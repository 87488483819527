























import { Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";

import HunchCard from "@/components/hunches/card.vue";
import FilterSelect from "@/components/general/filter.vue";
import HunchPlaceholder from "@/components/hunches/placeholder.vue";

@Component({
  components: {
    HunchCard,
    FilterSelect,
    HunchPlaceholder,
  },
})
export default class PageHunches extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Getter("hunch/all") hunches!: Hunch[];

  @Action("hunch/index") indexHunches!: (payload: SearchIndexPayload) => Promise<Hunch[]>;

  type: number[] = [];

  search = "";

  typeOptions: SelectOption[] = [
    { id: 1, label: "Idea", value: { slug: "idea" } },
    { id: 2, label: "Challenge", value: { slug: "challenge" } },
    { id: 3, label: "Opinion", value: { slug: "opinion" } },
    { id: 4, label: "Question", value: { slug: "question" } },
  ];

  labels: number[] = [];

  sort: number[] = [];

  loading = false;

  sortOptions: SelectOption[] = [
    { id: 1, label: "Nieuwste eerst", value: { created_at: "desc", id: "desc" } },
    { id: 2, label: "Oudste eerst", value: { created_at: "asc", id: "desc" } },
  ];

  mounted() {
    if (this.$route.query && this.$route.query.search) {
      this.search = `${this.$route.query.search}`;
    }

    this.searchHunches();
  }

  @Debounce(500)
  private searchHunches() {
    const payload: SearchIndexPayload = {
      per_page: 9,
      s: { created_at: "desc", id: "desc" },
      q: { and: { workspace_id: `${this.workspace.id}` } },
    };

    if (this.sort) {
      const foundSort: SelectOption | undefined = this.sortOptions.find((option: SelectOption) => this.sort.includes(option.id));
      if (foundSort) {
        payload.s = foundSort.value;
      }
    }

    if (this.type) {
      const foundType: SelectOption[] | undefined = this.typeOptions.filter((option: SelectOption) => this.type.includes(option.id));
      if (foundType) {
        payload.q = {
          ...payload.q,
          and: {
            ...(payload?.q?.and || {}),
            and: { type: foundType.map((option: SelectOption) => option!.value!.slug) },
          },
        };
      }
    }

    if (this.search) {
      payload.q = {
        ...payload.q,
        and: {
          ...(payload?.q?.and || {}),
          and: {
            // @ts-ignore
            ...(payload?.q?.and?.and || {}),
            or: {
              title: [`match:${this.search}^10`, `like:${this.search}*`],
            },
          },
        },
      };
    }

    this.loading = true;
    this.indexHunches(payload).finally(() => (this.loading = false));
  }

  get labelsOptions(): SelectOption[] {
    return [];
  }
}
